import React from 'react'
import styled from 'styled-components'

import PageWrapper from "../components/PageWrapper";
import { Box, Text, Title } from "../components/Core";

import image from '../assets/image/png/404-image.png'

const Image = styled.img`
    max-width: 500px;
    text-align: center;
    display: flex;
    justify-self: center;
`

const Error404Page = () => {
    return (
        <>
            <PageWrapper darkTheme>
                <Box pt={4} pb={4} style={{display: 'grid', textAlign: 'center'}} justifyContent="center">
                    <Image src={image} />
                    <Title>Sorry, page not found!</Title>
                    <Text variant="">This page is not in existance. Try going back and explore some other stuff we've made!</Text>
                </Box>
            </PageWrapper>
        </>
    )
}

export default Error404Page
